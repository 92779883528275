<template>
  <div>
    <b-tabs v-if="!hasPermission('warehouses.product_transfer_parties-index-all')">
      <b-tab
        v-for="tab in comingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChangeCTab(tab.id)"
      />
    </b-tabs>
    <div class="d-flex justify-content-end">
      <b-overlay :show="loadingBtn">
        <b-button
        variant="success"
        class="btn-tour-skip text-right mb-2"
        @click="tableToExcel"
      >
        <feather-icon
          icon="DownloadIcon"
          size="16"
        /> Юклаб олиш
      </b-button>
      </b-overlay>
    </div>
    <div class="card">
      <b-tabs>
        <b-tab
          v-for="tab in settingTabs"
          :key="tab.id"
          :title="tab.name"
          @click="onChange(tab.id)"
        >
          <b-overlay :show="loading">
            <good-table-column-search
              v-if="items && items.data"
              :items="items.data"
              :columns="tabColumns"
              :total="items.total"
              :page="page"
              :filter="filterModel"
              model="WarehouseTransferProduct"
              :has-show="true"
              @confirm="showParty"
              @getItems="getItems"
              @onPageChange="(p) => page = p"
            />
          </b-overlay>
        </b-tab>
      </b-tabs>
    </div>
    <!-- excel table -->
    <table
      v-show="false"
      ref="transferExcelTable"
      class="table table-bordered"
    >
      <thead>
        <tr>
          <th>№</th>
          <th>С какого склада</th>
          <th>На какой склад</th>
          <th>Дата</th>
          <th>Наименование</th>
          <th>Количиство</th>
          <th>Цена продажа</th>
          <th>Цена рассрочка</th>
        </tr>
      </thead>
      <tbody v-if="itemsX && itemsX.data && itemsX.data.length">
        <template
          v-for="(party, index) in itemsX.data"
        >
          <tr
            :key="'party'+index"
          >
            <td>{{ index+1 }}</td>
            <td width="400">
              {{ party.from_warehouse.name_uz }}
            </td>
            <td>
              {{ party.to_warehouse.name_uz }}
            </td>
            <td>
              {{ party.created_at?dateDotFormat(party.created_at):'' }}
            </td>
            <td colspan="4" />
          </tr>
          <tr
            v-for="(product, indexx) in party.product_transfers"
            :key="'product'+indexx+99"
          >
            <td colspan="4" />
            <td width="400">
              {{ product.product_category.name_uz }}
            </td>
            <td>
              {{ product.quantity }}
            </td>
            <td>
              <span v-if="product.product_category.price_dollar">
                {{ (Number(product.product_category.price_dollar) * dollarRate)|formatPrice }} so'm
              </span>
              <br>
              <span v-if="product.product_category.price_dollar">
                {{ product.product_category.price_dollar|formatPrice }} $
              </span>
            </td>
            <td>
              {{ product.product_category.price_dollar_installment |formatPrice }}$
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import { getItem } from '@/utils/storage'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      page: 1,
      loading: false,
      uri: 'data:application/vnd.ms-excel;base64,',
      template: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) { return window.btoa(unescape(encodeURIComponent(s))) },
      format(s, c) { return s.replace(/{(\w+)}/g, (m, p) => c[p]) },
      dollarRate: 0,
      filterModel: {
        per_page: 50,
        number: null,
        address: null,
        region_id: null,
        city_id: null,
        relations: 'toWarehouse|fromWarehouse|createdUser|productTransfers.productCategory',
        calculating_type_id: null,
        from_warehouse_id: null,
        to_warehouse_id: null,
        status: 0,
      },
      itemsX: null,
      items: null,
      loadingBtn:false,
      settingTabs: [
        {
          id: 0,
          name: this.$t('Для подтверждения'),
        },
        {
          id: 1,
          name: this.$t('Одобренные'),
        },
      ],
      comingTabs: [
        {
          id: 0,
          name: this.$t('Исходящие товары'),
        },
        {
          id: 1,
          name: this.$t('Поступление товары'),
        },
      ],
      tabColumns: [],
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'С какого склада',
          field: 'from_warehouse.name_uz',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'На какой склад',
          field: 'to_warehouse.name_uz',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Комментарий'),
          field: 'comment',
        },
        {
          label: 'Дата',
          field: 'created_at',
          isDate: true,
        },
        {
          label: this.$t('Действия'),
          field: 'confirm_action',
          isTransfer: true,
        },
        // {
        //   label: this.$t('Сумма'),
        //   field: 'cost',
        //   isAmount: true,
        // },

      ],
    }
  },
  computed: {
    ...mapGetters({
      // items: 'warehouse/GET_TRANSFER_ITEMS',
    }),
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.tabColumns = this.columns
    this.onChangeCTab(0)
    this.getDollarRates({ current_dollar_rate: 1 }).then(res => {
      if (res.data) {
        this.dollarRate = res.data.data[0]?.amount
      }
    })
  },
  methods: {
    ...mapActions({
      getDollarRates: 'resource/getDollarRates',
      getItemsAction: 'warehouse/transferProductsList',
    }),
    showParty(id) {
      this.$router.push({ name: 'WarehouseTransferProduct-show', params: { id, isTransfer: true } })
    },
    onChangeCTab(id) {
      const user = getItem('user')
      if (id == 0) {
        this.filterModel.from_warehouse_id = user.role_name == 'admin' ? null : user.warehouse_id
        this.filterModel.to_warehouse_id = null
      } else {
        this.filterModel.to_warehouse_id = user.warehouse_id
        this.filterModel.from_warehouse_id = null
      }
      this.getItems()
    },
    onChange(id) {
      this.filterModel.status = id
      const cols = [...this.columns]
      if (id == 1) {
        this.filterModel.relations = 'toWarehouse|fromWarehouse|createdUser|confirmedUser'
        const indexAction = cols.findIndex(el => el.field == 'confirm_action')
        if (typeof indexAction === 'number') {
          cols.splice(indexAction, 1)
        }
        cols.push({
          label: this.$t('Кто одобрил'),
          field: 'confirmed_user.name',
        })
        this.tabColumns = cols
      } else {
        this.tabColumns = this.columns
      }
      this.getItems()
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page }).then(res => {
        this.items = res.data
      })
      this.loading = false
    },
    async getXItems() {
      this.loadingBtn = true
      await this.getItemsAction({ ...this.filterModel, page: this.page, per_page:9999}).then(res => {
        this.items = res.data
      })
      this.loadingBtn = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') { return nameuz }
      return nameru
    },
    createParty() {
      this.$router.push({ name: 'TransferPartyCreate' })
    },
    async tableToExcel(table) {
      const tableeee = this.$refs.transferExcelTable
      this.loadingBtn = true
      await this.getItemsAction({ ...this.filterModel, page: this.page, per_page:9999}).then(res => {
        this.itemsX = res.data
        setTimeout(() => {
          if (!table.nodeType) table = tableeee
          const ctx = { worksheet: 'Перемещение товаров', table: table.innerHTML }
          const link = document.createElement('a')
          link.download = 'Перемещение товаров.xls'
          link.href = this.uri + this.base64(this.format(this.template, ctx))
          link.click()
        }, 10)
      })
      this.loadingBtn = false
    },
  },
}
</script>

<style scoped>

</style>
